<template>
  <div class="container-fluid container">
    <Breadcrumb />
    <el-card class="box-card">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{
            userMethods.displayName(userRoleProfile.basic_info.first_name, userRoleProfile.basic_info.last_name)
          }}
        </h1>
        <div v-if="isRoleAdmin()" class="tags">
          <UserTags :tags="userRoleProfile.basic_info.tags" />
          <UserTagsEditForm
            :userId="userRoleProfile.basic_info.id"
            :userRoles="roles"
            :userTags="userRoleProfile.basic_info.tags"
            @onSaved="initialProfile"
          />
        </div>
        <el-divider></el-divider>
      </div>

      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
          <BasicInfo
            :key="profileBasicInfoKey"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.parentInfo')"
          name="parent"
          v-if="isRoleParent()"
        >
          <ParentInfo
            :key="profileParentKey"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.studentInfo')"
          name="student"
          v-if="isRoleStudent()"
        >
          <StudentInfo
            :key="profileStudentKey"
            @initialProfile="initialProfile"
            :collectedCounselingSchools="collectedCounselingSchools"
            @fetchCollectedCounselingSchools="fetchCollectedCounselingSchools"
            @removeSchoolFromFavorite="removeSchoolFromFavorite"
          ></StudentInfo>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.teacherInfo')"
          name="teacher"
          v-if="isRoleTeacher()"
        >
          <TeacherInfo
            :key="profileTeacherKey"
            :teacherBillingInfos="teacherBillingInfos"
            @initialProfile="initialProfile"
          ></TeacherInfo>
        </el-tab-pane>
        <el-tab-pane
          v-if="isRoleAdmin()"
          :label="$t('userProfile.setting.title')"
          name="setting"
        >
          <SettingRole
            :id="userRoleProfile.basic_info.id"
            :roles="roles"
            :isActive="isActive"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane v-if="isRoleTeacher()" name="ContractInfo" label="合約">
          <ContractInfo />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import BasicInfo from "@/components/userProfile/BasicInfo";
import StudentInfo from "@/components/userProfile/StudentInfo";
import TeacherInfo from "@/components/userProfile/TeacherInfo";
import ParentInfo from "@/components/userProfile/ParentInfo";
import SettingRole from "@/components/userProfile/SettingRole";
import UserTags from "@/components/tags/UserTags";
import UserTagsEditForm from "@/views/user/UserTagsEditForm";
import roleMixin from "@/mixins/role";
import paymentApi from "@/apis/payment";
import collectSchoolApi from "@/apis/collectSchool";
import ContractInfo from "@/components/userProfile/ContractInfo";

export default {
  components: {
    Breadcrumb,
    BasicInfo,
    StudentInfo,
    TeacherInfo,
    ParentInfo,
    SettingRole,
    ContractInfo,
    UserTags,
    UserTagsEditForm
  },
  mixins: [roleMixin],
  data() {
    return {
      activeName: "info",
      collectedCounselingSchools: [],
      teacherBillingInfos: []
    };
  },
  async mounted() {
    if (this.$route.query.username) {
      this.$router.push({
        name: "userProfile",
        query: {
          username: this.$route.query.username
        }
      });
    }
    await this.initialProfile();
    if (this.isRoleTeacher()) {
      const teacherPaymentRes = await paymentApi.fetchTeacherPayments(this.$store.getters["user/getProfile"].id);
      this.teacherBillingInfos = teacherPaymentRes.teacher_user_payments;
    }
  },
  methods: {
    async initialProfile() {
      await this.$store.dispatch("profile/userRoleProfile");

      if (this.isRoleParent()) {
        await this.$store.dispatch("currency/fetchExchangeRate");
        this.$store.dispatch("profile/calculateDisplayBalance", {
          parentId: this.userRoleProfile.basic_info.id,
          balance: this.userRoleProfile.basic_info.account_balance,
          balanceCurrency: this.userRoleProfile.basic_info.account_balance_currency
        });
      }
    },
    async fetchCollectedCounselingSchools(type) {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.userRoleProfile.basic_info.id,
        counselingSchoolsType: this.userRoleProfile.student.counseling_schools_type,
        type
      });
      this.collectedCounselingSchools = counseling_schools;
    },
    async removeSchoolFromFavorite(schoolId) {
      await this.$store.dispatch("collectSchool/removeRelatedSchool", schoolId);
      this.fetchCollectedCounselingSchools();
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    userRoleProfile() {
      return this.$store.getters["profile/getUserData"];
    },
    profileBasicInfoKey() {
      return JSON.stringify(this.userRoleProfile.basic_info);
    },
    profileParentKey() {
      return JSON.stringify(this.userRoleProfile.parent);
    },
    profileStudentKey() {
      return JSON.stringify(this.userRoleProfile.student);
    },
    profileTeacherKey() {
      return JSON.stringify(this.userRoleProfile.instructor);
    },
    roles() {
      return this.userRoleProfile.basic_info.all_roles || [];
    },
    isActive() {
      return Boolean(this.userRoleProfile.basic_info.is_active);
    }
  }
};
</script>

<style scoped>
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: #42a16a;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  padding: 0px 20px;
  max-width: 95vw;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
</style>
