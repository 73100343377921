<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <el-form>
        <el-form-item :label="$t('userProfile.student.school')">
          <el-input
            v-model="newProfile.high_school"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.gradeLevel')">
          <GradeLevelSelector
            :value="newProfile.high_school_graduation_year"
            @change="
              value => {
                newProfile.high_school_graduation_year = value;
              }
            "
          />
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.classTaken')">
          <el-input
            v-model="newProfile.taken_class_where"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.comments')">
          <el-input
            v-model="newProfile.taken_class_comments"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.prefLang')">
          <el-input
            v-model="newProfile.lang_pref"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.knowUsFrom')">
          <el-input v-model="newProfile.know_us" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.referral')">
          <el-input
            v-model="newProfile.recommendation_info"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">{{
          $t("userProfile.dialog.cancel")
        }}</el-button>
        <el-button type="primary" @click="submitChange">{{
          $t("userProfile.dialog.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { GradeLevelSelector } from "@/components/selector";

export default {
  components: {
    GradeLevelSelector
  },
  props: ["type", "editDialog", "profile"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialog,
      newProfile: {}
    };
  },
  mounted() {
    this.newProfile = _.cloneDeep(this.profile);
  },
  methods: {
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async submitChange() {
      this.dialogFormVisible = false;
      await this.$store.dispatch(
        "profile/updateStudentProfile",
        this.newProfile
      );
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>

<style></style>
