<template>
  <div class="pl-2 mt-3">
    <h4 style="color:#42a16a">SAT Mocks：</h4>
    <a
      :href="
        `https://sat.ivy-way.com/testresults?type=composePractices?token=${token}`
      "
    >
      <el-button type="primary">
        <i class="fa fa-eye"></i>
        查看SAT成績
      </el-button>
    </a>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

import { instant } from "@ivy-way/material";

export default {
  props: ["sat_results"],
  components: {},
  computed: {
    ...mapState("user", ["profile", "token"]),
    instant() {
      return instant;
    }
  },
  data() {
    return {
      bestScore: ""
    };
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.generalSub {
  font-size: 15px;
  font-weight: bold;
}
.superScoreItem {
  font-size: 15px;
  font-weight: bold;
}
.superScoreTotal {
  font-size: 18px;
  font-weight: bold;
}
</style>
