import { render, staticRenderFns } from "./EditDialogBasic.vue?vue&type=template&id=2e12a95d"
import script from "./EditDialogBasic.vue?vue&type=script&lang=js"
export * from "./EditDialogBasic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports