<template>
  <div>
    <br />
    <el-dialog
      :title="$t('userProfile.basic.warning')"
      :visible.sync="passwordDialogVisible"
      width="80%"
    >
      <span>{{ $t("userProfile.basic.askChangePass") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">{{
          $t("userProfile.basic.cancel")
        }}</el-button>
        <el-button type="primary" @click="changePassword">{{
          $t("userProfile.basic.change")
        }}</el-button>
      </span>
    </el-dialog>
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.basic.title") }}
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                @click="
                  editDialog = true;
                  type = 'basicInfo';
                "
              >
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
      <div class="pl-2">
        <el-avatar shape="square" :size="70" :src="squareUrl"></el-avatar>
      </div>
    </el-row>
    <template v-if="editDialog">
      <EditDialogBasic
        :type="type"
        :editDialog="editDialog"
        :profile="profile"
        @updateDialog="updateDialog"
      ></EditDialogBasic>
    </template>
    <el-table :data="basicTableData" :show-header="false">
      <el-table-column width="auto">
        <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
      </el-table-column>

      <el-table-column prop="data" width="auto">
        <template slot-scope="scope">
          <template v-if="scope.row.propertyName === 'password'">
            <el-button
              @click="passwordDialogVisible = true"
              class="btn-edit"
              type="text"
              size="small"
              >{{ $t("userProfile.basic.changePass") }}</el-button
            >
          </template>
          <template v-else>
            <slot>{{ scope.row.data }}</slot>
          </template>
        </template>
      </el-table-column>

      <el-table-column width="auto"></el-table-column>
    </el-table>
    <br />
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.basic.contactInfo") }}
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                @click="
                  editDialog = true;
                  type = 'contactInfo';
                "
              >
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
    </el-row>
    <el-table :data="contactTableData" :show-header="false">
      <el-table-column prop="name" width="auto">
        <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
      </el-table-column>
      <el-table-column prop="data" width="auto"></el-table-column>

      <el-table-column width="auto"></el-table-column>
    </el-table>
    <br />
    <div v-if="isUserRoleParent">
      <el-row>
        <div class="pb-3 d-flex">
          <span class="pt-1 pr-2">
            <h3>
              {{ $t("balance.title") }}
              <router-link
                v-if="isRoleAdmin()"
                :to="{ name: 'FinanceAdd', query: { parent: username } }"
              >
                <i class="fas fa-plus" />
              </router-link>
            </h3>
          </span>
        </div>
      </el-row>
      <div class="summary">
        <router-link
          target="_blank"
          :to="{ name: 'Balance', query: { username: username } }"
        >
          <h2>{{ isZeroAndDoNotDisplay(balance) }}</h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EditDialogBasic from "@/components/userProfile/EditDialogBasic";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
export default {
  components: {
    EditDialogBasic
  },
  mixins: [moneyMixin, roleMixin],
  data() {
    return {
      squareUrl: "",
      profile: {},
      type: "",
      basicTableData: [
        {
          name: "userProfile.basic.username",
          data: "",
          edit: true,
          propertyName: "username"
        },
        {
          name: "userProfile.basic.password",
          edit: false,
          propertyName: "password"
        },
        {
          name: "userProfile.basic.role",
          data: "",
          edit: false,
          propertyName: "role"
        },
        {
          name: "userProfile.basic.joined",
          data: "",
          edit: false,
          propertyName: "joined"
        },
        {
          name: "userProfile.basic.currency",
          data: "",
          edit: false,
          propertyName: "default_currency"
        }
      ],
      contactTableData: [
        {
          name: "userProfile.basic.email",
          data: "",
          edit: false,
          propertyName: "email"
        },
        {
          name: "userProfile.basic.phone",
          data: "",
          edit: true,
          propertyName: "phone"
        },
        {
          name: "userProfile.basic.facebook",
          data: "",
          edit: true,
          propertyName: "facebook_id"
        },
        {
          name: "userProfile.basic.line",
          data: "",
          edit: true,
          propertyName: "line_id"
        },
        {
          name: "userProfile.basic.wechat",
          data: "",
          edit: true,
          propertyName: "wechat_id"
        },
        {
          name: "userProfile.basic.skype",
          data: "",
          edit: true,
          propertyName: "skype_id"
        },
        {
          name: "userProfile.basic.address",
          data: "",
          edit: true,
          propertyName: "address"
        }
      ],
      editDialog: false,
      passwordDialogVisible: false
    };
  },
  mounted() {
    this.profile = this.userRoleProfile.basic_info;
    // check the photo existing or not
    if (this.profile.photo_url) {
      this.squareUrl = this.profile.photo_url;
    } else {
      this.squareUrl = require("@/assets/avatar.png");
    }
    this.assignData();
  },

  methods: {
    async changePassword() {
      this.passwordDialogVisible = false;
      const url = await this.$store.dispatch("profile/changePassword", this.id);
      window.location = url.reset_url;
    },
    async updateDialog(status) {
      this.editDialog = false;
      if (status === "submit") {
        await this.$emit("initialProfile");
        this.profile = this.userRoleProfile.basic_info;
        this.assignData();
      }
    },
    assignData() {
      this.squareUrl = this.profile.photo_url;
      this.basicTableData[0].data = this.profile.username;
      this.basicTableData[2].data = this.profile.role;
      this.basicTableData[3].data = this.profile.joined;
      this.basicTableData[4].data = this.profile.default_currency;

      this.contactTableData[0].data = this.profile.email;
      this.contactTableData[1].data = this.profile.phone;
      this.contactTableData[2].data = this.profile.facebook_id;
      this.contactTableData[3].data = this.profile.line_id;
      this.contactTableData[4].data = this.profile.wechat_id;
      this.contactTableData[5].data = this.profile.skype_id;
      this.contactTableData[6].data = this.profile.address;
    },
    isZeroAndDoNotDisplay(balance) {
      return balance === "$0 TWD" ? "0" : balance;
    }
  },
  computed: {
    userRoleProfile() {
      return this.$store.getters["profile/getUserData"];
    },
    table() {
      return this.$t("userProfile.basic.test");
    },
    username() {
      if (!this.userRoleProfile.basic_info) return "";
      return this.userRoleProfile.basic_info.username;
    },
    isUserRoleParent() {
      if (Object.keys(this.userRoleProfile.basic_info).length === 0) return false;
      return this.userRoleProfile.basic_info.all_roles.includes("parent");
    },
    balance() {
      if (!this.userRoleProfile.basic_info && this.isUserRoleParent) return "";
      return this.addCurrencySymbol(
        Number(this.$store.getters["profile/getDisplayBalance"]),
        this.userRoleProfile.basic_info.account_balance_currency
      );
    }
  },
  watch: {}
};
</script>

<style scoped>
.btn-edit:focus {
  outline: none;
}

.summary {
  padding: 28px 0;
  border: 2px solid #e2e2e2;
  margin: 10px auto;
  text-align: center;
}

.summary h2 {
  color: #42a16a;
}

.summary a {
  text-decoration: none;
}
</style>

<style>
.tooltipColor.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: rgba(66, 161, 106, 0.9);
}
.tooltipColor.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: rgba(66, 161, 106, 0.9);
}
.tooltipColor {
  background: rgba(66, 161, 106, 0.9) !important;
}
</style>
