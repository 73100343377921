<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <template v-if="type === 'teacherInfo'">
        <el-form>
          <!-- <el-form-item :label="$t('userProfile.teacher.college')">
            <el-input
              v-model="newProfile.college"
              autocomplete="off"
            ></el-input>
          </el-form-item>-->

          <!-- college -->
          <el-tabs type="border-card">
            <el-tab-pane :label="$t('userProfile.teacher.college') + ' (EN)'">
              <el-form-item
                :label="$t('userProfile.teacher.college') + ' (EN)'"
              >
                <el-input v-model="newProfile.college.en"></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane :label="$t('userProfile.teacher.college') + ' (TW)'">
              <el-form-item
                :label="$t('userProfile.teacher.college') + ' (TW)'"
              >
                <el-input v-model="newProfile.college.tw"></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane :label="$t('userProfile.teacher.college') + ' (CN)'">
              <el-form-item
                :label="$t('userProfile.teacher.college') + ' (CN)'"
              >
                <el-input v-model="newProfile.college.cn"></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <!-- grade level -->
          <el-form-item
            :label="$t('userProfile.teacher.gradeLevel')"
            class="mt-4 mb-5"
          >
            <el-input v-model="newProfile.college_graduation_year" />
          </el-form-item>
          <!-- meeting_link_url -->
          <el-form-item
            class="mt-4 mb-5"
          >
            <div slot="label">
              <el-popover placement="top" trigger="hover">
                <div>
                  <el-alert type="success" :closable="false" class="p-1">
                    <div>
                      Log into your own Zoom or Google Meet account, create a
                      new<br />
                      room that is permanent, and paste that link here. You’ll
                      use<br />
                      that same link for all your online classes.<br />
                      The link should look like <b>https://zoom.us/s/1234</b
                      ><br />
                      or <b>https://meet.google.com/aaa-bbb-ccc</b>
                    </div>
                  </el-alert>
                </div>
                <span
                  class="text-success"
                  style="cursor:pointer"
                  slot="reference"
                >
                  {{$t('userProfile.teacher.meeting_link_url')}}
                  <i class="fas fa-question-circle"></i
                ></span>
              </el-popover>
            </div>
            <el-input v-model="newProfile.meeting_link_url" />
          </el-form-item>

          <!-- major -->
          <el-tabs type="border-card">
            <el-tab-pane :label="$t('userProfile.teacher.major') + ' (EN)'">
              <el-form-item :label="$t('userProfile.teacher.major') + ' (EN)'">
                <el-input v-model="newProfile.college_major.en"></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane :label="$t('userProfile.teacher.major') + ' (TW)'">
              <el-form-item :label="$t('userProfile.teacher.major') + ' (TW)'">
                <el-input v-model="newProfile.college_major.tw"></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane :label="$t('userProfile.teacher.major') + ' (CN)'">
              <el-form-item :label="$t('userProfile.teacher.major') + ' (CN)'">
                <el-input v-model="newProfile.college_major.cn"></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <!-- bio -->
          <el-tabs type="border-card" class="mt-5">
            <el-tab-pane
              :label="$t('userProfile.teacher.publicIntro') + ' (EN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.publicIntro') + ' (EN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.bio.en"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.publicIntro') + ' (TW)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.publicIntro') + ' (TW)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.bio.tw"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.publicIntro') + ' (CN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.publicIntro') + ' (CN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.bio.cn"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <!-- tutoringBio -->
          <el-tabs type="border-card" class="mt-5">
            <el-tab-pane
              :label="$t('userProfile.teacher.tutoringBio') + ' (EN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.tutoringBio') + ' (EN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.tutoring_bio.en"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.tutoringBio') + ' (TW)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.tutoringBio') + ' (TW)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.tutoring_bio.tw"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.tutoringBio') + ' (CN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.tutoringBio') + ' (CN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.tutoring_bio.cn"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <!-- counselingBio -->
          <el-tabs type="border-card" class="mt-5">
            <el-tab-pane
              :label="$t('userProfile.teacher.counselingBio') + ' (EN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.counselingBio') + ' (EN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.counseling_bio.en"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.counselingBio') + ' (TW)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.counselingBio') + ' (TW)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.counseling_bio.tw"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.teacher.counselingBio') + ' (CN)'"
            >
              <el-form-item
                :label="$t('userProfile.teacher.counselingBio') + ' (CN)'"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="newProfile.counseling_bio.cn"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <!-- ans_to_extracurriculars -->
          <!-- <el-form-item
            :label="$t('userProfile.teacher.ans_to_extracurriculars')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_extracurriculars_placeholder')"
              v-model="newProfile.ans_to_extracurriculars"
            />
          </el-form-item> -->
          <!-- ans_to_teaching_years -->
          <el-form-item
            :label="$t('userProfile.teacher.ans_to_teaching_years')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_teaching_years_placeholder')"
              v-model="newProfile.ans_to_teaching_years"
            />
          </el-form-item>
          <!-- ans_to_student_numbers -->
          <el-form-item
            :label="$t('userProfile.teacher.ans_to_student_numbers')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_student_numbers_placeholder')"
              v-model="newProfile.ans_to_student_numbers"
            />
          </el-form-item>
          <!-- ans_to_capable_subjects -->
          <el-form-item
            :label="$t('userProfile.teacher.ans_to_capable_subjects')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_capable_subjects_placeholder')"
              v-model="newProfile.ans_to_capable_subjects"
            />
          </el-form-item>
          <!-- ans_to_relevant_work -->
          <el-form-item
            :label="$t('userProfile.teacher.ans_to_relevant_work')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_relevant_work_placeholder')"
              v-model="newProfile.ans_to_relevant_work"
            />
          </el-form-item>
          <!-- ans_to_students_perform -->
          <el-form-item
            :label="$t('userProfile.teacher.ans_to_students_perform')"
            class="mt-4 mb-5"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('userProfile.teacher.ans_to_students_perform_placeholder')"
              v-model="newProfile.ans_to_students_perform"
            />
          </el-form-item>
        </el-form>
      </template>

      <!-- TODO: 這幾個 API 欄位還沒開-->
      <template v-if="type === 'availability'">
        <el-form>
          <el-form-item :label="$t('userProfile.teacher.hoursAvailable')">
            <el-input
              v-model="newProfile.hours_available"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.teacher.teachingPref')">
            <el-input autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item v-if="isRoleAdmin()">
            <div>{{ $t("userProfile.teacher.salary") }}</div>
            <el-row>
              <el-col :span="2">Online:</el-col>
              <el-col :span="6">
                <InputMoney
                  :money="Number(newProfile.online_salary) || 0"
                  :currency="newProfile.online_currency || 'TWD'"
                  @changeMoney="
                    price => {
                      newProfile.online_salary = price;
                    }
                  "
                  @changeCurrency="
                    currency => {
                      newProfile.online_currency = currency;
                    }
                  "
                />
              </el-col>
              <el-col :span="2">In-person:</el-col>
              <el-col :span="6">
                <InputMoney
                  :money="Number(newProfile.in_person_salary) || 0"
                  :currency="newProfile.in_person_currency || 'TWD'"
                  @changeMoney="
                    price => {
                      newProfile.in_person_salary = price;
                    }
                  "
                  @changeCurrency="
                    currency => {
                      newProfile.in_person_currency = currency;
                    }
                  "
                />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            v-if="isRoleAdmin()"
            :label="$t('userProfile.teacher.note')"
          >
            <el-input v-model="newProfile.notes" />
          </el-form-item>
        </el-form>
      </template>

      <template v-if="type === 'billing'">
        <el-form>
          <el-form-item label="Hours Available">
            <el-input
              v-model="newProfile.hours_available"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="Teaching Preference">
            <el-input autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">
          {{ $t("userProfile.dialog.cancel") }}
        </el-button>
        <el-button type="primary" @click="submitChange">
          {{ $t("userProfile.dialog.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { InputMoney } from "@/components/input";
import roleMixin from "@/mixins/role";

export default {
  props: ["type", "editDialog", "profile"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialog,
      newProfile: {}
    };
  },
  components: {
    InputMoney
  },
  mixins: [roleMixin],
  created() {
    this.newProfile = _.cloneDeep(this.profile);
  },
  mounted() {},
  methods: {
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async submitChange() {
      this.dialogFormVisible = false;
      await this.$store.dispatch(
        "profile/updateTeacherProfile",
        this.newProfile
      );
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  text-align: left;
  line-height: 20px;
}
</style>
